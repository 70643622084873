import {
  mrAuto,
  mlAuto,
  cardTitle,
  whiteColor,
  infoColor,
  hexToRgb,
  title
} from "assets/jss/material-kit-pro-react.js";

const pricingStyle = {
  mrAuto,
  mlAuto,
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important",
    "& small": {
      color: "rgba(" + hexToRgb(whiteColor) + ",0.8)!important"
    }
  },
  textCenter: {
    textAlign: "center"
  },
  pricingSection: {
    padding: "0px 0px 20px 0px"
  },
  textInfo: {
    color: infoColor[0] + " !important"
  },
  finalSaleTitle: {
    color: 'red',
    fontSize: 38
  },
  finalSaleDescription: {
    fontSize: 22
  },
  title: {
    ...title
  },
  link: {
    textDecoration: "none",
    color: infoColor[0],
    "&:visited": {
      color: infoColor[0]
    },
    "&:hover, &:focus": {
      color: infoColor[0],
      opacity: '0.7'
    }
  },
  cardList: {
      maxWidth: "95%!important",
  },
  cardCategory: {
    fontSize: 18
  },
  border: {
    border: `solid 1px ${infoColor[4]}`,
    borderRadius: 10,
    padding: 30,
    margin: 10
  }
};

export default pricingStyle;
