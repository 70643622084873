import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Mailchimp from 'react-mailchimp-form'
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import contactStyle from "assets/jss/material-kit-pro-react/views/salesSections/contactStyle.js";

const useStyles = makeStyles(contactStyle);

const hjid = 2162965;
const hjsv = 6;

hotjar.initialize(hjid, hjsv);

const PixelId = 484911295362513;
const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(PixelId, advancedMatching, options);

ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle(PixelId, event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom(PixelId, event, data);

export default function SectionContact() {
  const classes = useStyles();
  return (
    <div className={classes.aboutUs}>
      <GridContainer>
        <GridItem sm={12} className={classNames(classes.mrAuto, classes.mlAuto)}>
          <h2 className={classNames(classes.title, classes.textCenter)}>
          <i className="fas fa-arrow-down" /> FILL OUT THE FORM BELOW TO GET STARTED <i className="fas fa-arrow-down" />
          </h2>
        </GridItem>
        </GridContainer>
        <GridContainer>
        <GridItem sm={12} md={9} className={classNames(classes.mrAuto, classes.mlAuto)}>
          <Mailchimp
            action='https://utahswimacademy.us17.list-manage.com/subscribe/post?u=24e95de980f906b5441c857e2&amp;id=8953b447b3'
            fields={[
              {
                name: 'EMAIL',
                placeholder: 'Email',
                type: 'email',
                required: true
              },
              {
                name: 'FNAME',
                placeholder: 'First Name',
                type: 'text',
                required: true
              },
              {
                name: 'LNAME',
                placeholder: 'Last Name',
                type: 'text',
                required: true
              }
            ]}
            messages = {
              {
                sending: "Sending...",
                success: "Success! You'll receive an Email for more details about our Online Courses!",
                error: "An unexpected internal error has occurred.",
                empty: "Email Required",
                duplicate: "Too many subscribe attempts for this email address",
                button: "GET ACCESS NOW!"
              }
            }
            className={classNames(classes.mailForm)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
