export const FAQS_LIST = [
    {
      title: "Are your lessons one on one?",
      description: "Yes! All lessons are 20 minute private lessons."
    },
    {
      title: "Do you have to schedule all lessons back to back? What if there's a big break in the middle?",
      description: "No! You schedule all lessons at your convenience. Lessons are good for a year after purchase. Long breaks equal lost skills, but they usually will pick them back up quickly when they start back up again (depending on their age). It's good to come every once in a while to maintain skills."
    },
    {
      title: "How young can my baby start?",
      description: "We have started as young as 3-4 months. 6-8 months is more typical. Young babies learn the skills really fast, but will need a regular maintenance program to maintain the skills as they can forget them quickly if they don’t continue to come to lessons regularly."
    },
    {
      title: "How many lessons does my child need?",
      description: "We recommend 20-30 lessons per child for them to master safety skills and move to a maintenance program."
    },
    {
      title: "How frequently should my child come to lessons?",
      description: "When starting out we recommend coming every day (5x) for the first 2-3 weeks. This is the fastest way for them to master their safety skills and start moving to swimming strokes. We will taper down from there - 3x a week for a few weeks and then down to 1-2 times a week for maintenance."
    },
    {
      title: "What are the personality types listed in the teacher bios?",
      description: "One of the things that makes us unique is that we try to match children with the right instructor and teach them according to their personality type. We use the 4 personalities outlined in the book “The Child Whisperer” by Carol Tuttle. For more information on the personality types see 'About Us' -> 'Our Team'."
    },
    {
      title: "Do I need to stick with the same instructor or is it ok to switch around?",
      description: "Coming more frequently is more important than having the same instructor every time and our instructors are good at communicating with you about where your child is and what they need to be working on. However, we recommend trying to stick with the same 2-3 instructors when possible as that allows the instructor and child to get to know each other better. Most kids will do ok switching instructors."
    },
    {
      title: "What should we bring to our lessons?",
      description: "Until your child is consistently and reliably potty trained PLEASE DOUBLE DIAPER! This means a disposable swim diaper AND a waterproof layer on top - reusable swim diapers or plastic pants for covering cloth diapers work really well. This allows us to control accidents in the pool without having to shut down the entire pool. Please help us keep our pool clean! We have goggles you can use, but if your child wants their own goggles they are more than welcome. Make sure to bring a towel and be prepared for the weather - cold mornings, sun screen etc."
    },
    {
      title: "My child threw up after their lesson? Are they ok? What about dry drowning?",
      description: "As children - especially young babies - learn to hold their breath in the water they will swallow a lot of water. This is NORMAL, a reflex, and part of the process. They will soon move to swallowing air and then figure out how to hold their breath. This is why we ask you not to have them eat before lessons. As their bellies full with water they will vomit it out and breakfast will come out sometimes too. We do our best to frequently help them burp and empty that water out of their bellies and try to be sure they are emptied out before we hand them off to you, but sometimes they may still throw up some water after lessons. Dry drowning is actually called secondary drowning, and only happens AFTER a drowning incident where water has entered the LUNGS. It happens when a person has had to be revived after a drowning. In our lessons the children will swallow water - but it is going into their bellies, not their lungs."
    }
  ];