import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// sections of this Page
import SectionFirstDay from "./Sections/SectionFirstDay.js";
import SectionFood from "./Sections/SectionFood.js";
import SectionContact from "./Sections/SectionContact.js";
import SectionServices from "views/PricingPage/Sections/SectionServices.js";
import SectionEmailSubscription from "views/ContactUsPage/Sections/SubscribeLine.js";
import approachPageStyle from "assets/jss/material-kit-pro-react/views/approachPageStyle.js";

const useStyles = makeStyles(approachPageStyle);

export default function ApproachPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Tracker();
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/headers/favs/favs6.jpg")}
        small
        title="Getting Started"
        description="You've Schedule Your Kids Lessons, What's Next?"
        backgroundPosition={"center center"} />
      <div className={classes.main}>
        <SectionFirstDay />
        <SectionFood />
        <br /><br />
        <SectionServices description="Haven't Scheduled a Lesson Yet?" />
        <SectionContact />
      </div>
      <SectionEmailSubscription />
      <Footer theme="white" />
    </div>
  );
}
