import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
// sections of this Page
import SectionHeaders from "./Sections/SectionHeaders.js";
import SectionTeams from "./Sections/SectionTeams.js";
import SectionProjects from "./Sections/SectionProjects.js";
import SectionBook from "./Sections/SectionBook.js";
// Sections from outsource pages
import SectionSocialNetwork from "views/HomePage/Sections/SectionSocialNetwork.js";
import SectionSchedule from "views/SchedulingPage/Sections/SectionFeatures.js";
import SectionContact from "views/ApproachPage/Sections/SectionContact.js";
import SectionEmailSubscription from "views/ContactUsPage/Sections/SubscribeLine.js";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

const useStyles = makeStyles(homePageStyle);

export default function HomePage() {
  React.useEffect(() => {
    Tracker();
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href).scrollIntoView();
    }
    window.addEventListener("scroll", updateView);
    updateView();
    return function cleanup() {
      window.removeEventListener("scroll", updateView);
    };
  });
  const updateView = () => {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");

    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
          window.pageYOffset &&
        contentSections[i].offsetTop +
          contentSections[i].scrollHeight -
          window.innerHeight / 2 >
          window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  };
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div className={classes.main}>
        <SectionHeaders id="home" />
        <div className={classes.container}>
        {/* <h1 style={{ fontSize: 24, color: 'orange', fontWeight: 700 }}>For COVID-19 safety, parents are currently not allowed to stay in the hard structure during lessons</h1> */}
        </div>
        <div className="cd-section" id="services">
          <SectionTeams />
          <div className={classes.container}><hr /></div>
          <SectionSchedule />
        </div>
        <SectionBook id="book" />
        <SectionProjects id="about" />
        <div className="cd-section" id="contacts">
          <SectionSocialNetwork />
          <SectionContact />
        </div>
        <SectionEmailSubscription />
        <Footer theme="white" />
      </div>
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              href="#home"
              data-number="1"
              className="is-selected"
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("home");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">HOME</span>
            </a>
          </li>
          <li>
            <a
              href="#services"
              data-number="2"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("services");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">SERVICES</span>
            </a>
          </li>
          <li>
            <a
              href="#book"
              data-number="3"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("book");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">SCHEDULE TODAY</span>
            </a>
          </li>
          <li>
            <a
              href="#about"
              data-number="4"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("about");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">ABOUT US</span>
            </a>
          </li>
          <li>
            <a
              href="#contacts"
              data-number="5"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("contacts");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">CONTACT US</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
