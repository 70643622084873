import {
  mrAuto,
  mlAuto,
  title,
  description,
  cardTitle,
  grayColor,
  infoColor,
  container
} from "assets/jss/material-kit-pro-react.js";

const teamStyle = {
  ...container,
  mrAuto,
  mlAuto,
  // title: {
  //   ...title
  // },
  title: {
    ...title,
    textAlign: "center",
    color: 'white',
    padding: '0px 0 50px'
  },
  description: {
    ...description,
    marginBottom: "80px",
    fontWeight: 400
  },
  cardTitle: {
    ...cardTitle,
    color: 'white!important'
  },
  cardDescription: {
    color: 'white',
    fontWeight: 400
  },
  team: {
    padding: "0px 0px 0px 0px",
  },
  textCenter: {
    textAlign: "center!important"
  },
  img: {
    width: "100%",
    height: "auto"
  },
  textMuted: {
    color: grayColor[7] + " !important"
  },
  justifyContent: {
    justifyContent: "center!important"
  },
  services: {
    backgroundColor: infoColor[1],
    padding: 50,
  }
};

export default teamStyle;
