import React, {Fragment} from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import headersStyle from "assets/jss/material-kit-pro-react/views/homeSections/headersStyle.js";

import header1 from "assets/img/headers/landing.jpg";
import header2 from "assets/img/headers/services.jpg";
import header3 from "assets/img/headers/favs/favs6.jpg";
import SmallLogo from "assets/img/logos/logo.svg";
import WordLogoLong from "assets/img/logos/logo-word-long.svg";

const useStyles = makeStyles(headersStyle);

export default function SectionHeaders({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: window.innerWidth > 900,
    fade: true
  };
  const sayings = [
    {
      title: "EMPOWERING PARENTS & CHILDREN WITH WATER",
      img: header1
    },
    {
      title: "OUR PURPOSE AND PASSION: SAVING LIVES, ONE PERSON AT A TIME!",
      img: header2
    },
    {
      title: "LET US TEACH YOUR CHILDREN LIFE SAVING SKILLS YEAR AROUND!",
      img: header3
    }
  ]
  return (
    <div className="cd-section" {...rest}>
      <div>
        <Carousel {...settings}>
        {sayings.map((item,i) =>
          <div key={'header'+i}>
            <div className={classes.pageHeader} style={{ backgroundImage: `url("${item.img}")` }}>
              <div className={classes.container}>
                <GridContainer>
                  <GridItem>
                    <div className={classes.brand}>
                      <div className={classes.logoWrapper}>
                        {window.innerWidth > 900 ?
                        <img src={WordLogoLong} alt={"Utah Swim Academy"} style={{width: 600}} className={classes.logoImg} />
                        :
                        <img src={SmallLogo} alt={"Utah Swim Academy"} style={{width: 200}} className={classes.logoImg} />
                        }
                        {window.innerWidth > 900 ? 
                        <Fragment></Fragment> 
                        :
                        <h1 className={classes.logoTitle}>
                          {"WELCOME"}
                        </h1>
                        }
                        <br className={classes.clear} />
                      </div>
                      <h3 className={classes.wrapperDescription}>
                        {item.title}
                      </h3>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          )}
        </Carousel>
      </div>
    </div>
  );
}
