import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import bodyStyle from "assets/jss/material-kit-pro-react/views/salesSections/bodyStyle.js";

const useStyles = makeStyles(bodyStyle);

export default function SectionTeam() {
  const classes = useStyles();
  return (
    <div>
      <h2 className={classes.title}>DID YOU KNOW THAT DROWNING IS STILL THE LEADING CAUSE OF UNINTENTIONAL DEATH FOR KIDS UNDER THE AGE OF 5?</h2>
      <h4 className={classes.subtitle}>THAT’S WHY IT IS NEVER TOO LATE OR TOO SOON TO FAMILIARIZE SOMEONE TO WATER</h4>
      <p className={classes.body1}>If you are reading this then you must have concerns about water safety and the risks of someone drowning in and around water, or you are interested in starting a new career by teaching others swim safety.</p>
      <h4 className={classes.body2}>Whatever the motivation that brought you to this page</h4>
      <h3 className={classes.body3}>NOW IS A GREAT TIME TO LEARN <span className={classes.body4}>LIFE-SAVING</span> SURVIVAL SWIMMING SKILLS!</h3>
      <p className={classes.body5}>For the last 15 years, Utah Swim Academy, has been teaching lifesaving water safety skills to infants, children, teens and even adults at our Utah facility. </p>
      <p className={classes.body5}>Our unique one-on-one personalized approach is what sets us apart from every other school out there, and we want to teach you too!</p>
      <p className={classes.body5}>The Utah Swim Academy Online Course will help bring the knowledge and expertise to your own school - armed with a unique personality-based approach to teaching swimming that no one else offers.</p>
      <ul className={classes.bodyList}>
        <li className={classes.list}><i className="fas fa-arrow-right" /> Designed for easy learning at your own pace - right from home.</li>
        <li className={classes.list}><i className="fas fa-arrow-right" /> Access anytime and anywhere in the world!</li>
        <li className={classes.list}><i className="fas fa-arrow-right" /> Learn time-tested, and proven techniques that work.</li>
        <li className={classes.list}><i className="fas fa-arrow-right" /> Learn from us and teach your child and others.</li>
      </ul>
    </div>
  );
}
