import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import videoStyle from "assets/jss/material-kit-pro-react/views/salesSections/videoStyle.js";

import introVideo from "assets/videos/introduction.mp4";

const useStyles = makeStyles(videoStyle);

export default function SectionTeam() {
  const classes = useStyles();
  return (
    <div>
      <h4 className={classes.title}>For an extremely limited time, we want to give you access to preview our course and see if it is right for you</h4>
      <div className={classes.iframeContainer}>
        <video width="100%" controls autoPlay muted>
          <source src={introVideo} type="video/mp4" />
        Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}
