import React, { useState } from "react";
// database
import * as DATABASE from 'db';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui icons
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Search from "@material-ui/icons/Search";
// styling
import featuresStyle from "assets/jss/material-kit-pro-react/views/faqSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionQuestions() {
  const classes = useStyles();
  const [list] = useState(DATABASE.FAQS_LIST);
  const [newList, setNewList] = useState(list);
  const handleSearch = (input) => {
    let tempList = list.filter(obj => Object.keys(obj).some(key => {
      if(['title','description'].includes(key)){ return JSON.stringify(obj[key]).toLowerCase().includes(input.toLowerCase()) } else { return null };
    }));
    setNewList(tempList);
  };
  return (
    <div className={classes.featuresSection}>
      <div>
        <h2 className={classes.title}>Search or Browse</h2>
        <h4 className={classes.description}>Search or Browse our Frequently Asked Questions below to find answers to your questions</h4>
      </div>
      <GridContainer>
        <GridItem md={8} sm={12} xs={12}>
          <CustomInput
            labelText="Search FAQ"
            name="input"
            update={(e) => handleSearch(e.target.value)}
            id="float"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <div style={{ minHeight: 800 }}>
        {newList.map(item =>
        <GridItem md={12} sm={12} xs={12} key={item.title}>
          <InfoArea
            title={item.title}
            description={item.description}
            icon={QuestionAnswer}
            iconColor="info"
          />
        </GridItem>
        )}
        </div>
      </GridContainer>
    </div>
  );
}
