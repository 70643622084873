import {
  container,
  section,
  sectionDark,
  mlAuto,
  mrAuto,
  title,
  description,
  cardTitle,
  roseColor,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor
} from "assets/jss/material-kit-pro-react.js";

const pricingSection = {
  container,
  mlAuto,
  mrAuto,
  title: {
    ...title,
    color: infoColor[4],
    fontSize: `${window.innerWidth > 900 ? "50px" : "35px"}!important`
  },
  subtitle: {
    ...title,
    color: infoColor[0],
    fontSize: `${window.innerWidth > 900 ? "30px" : "35px"}!important`
  },
  cardTitle,
  description,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important"
  },
  icon: {
    width: "2.25rem",
    height: "2.25rem",
    fontSize: "2.25rem"
  },
  primary: {
    color: primaryColor[0]
  },
  warning: {
    color: warningColor[0]
  },
  danger: {
    color: dangerColor[0]
  },
  success: {
    color: successColor[0]
  },
  info: {
    color: infoColor[0]
  },
  rose: {
    color: roseColor[0]
  },
  gray: {
    color: grayColor[0]
  },
  personalDescription: {
    color: grayColor[0]
  },
  iconWrapper: {
    marginTop: "24px",
    marginRight: "10px"
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  },
  sectionGray: {
    background: grayColor[14]
  },
  section: {
    ...section,
    ...sectionDark,
    position: "relative",
    "& $container": {
      position: "relative",
      zIndex: "2"
    },
    "& $description": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.5)"
    },
    "& $cardCategory": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)"
    },
    "& $title": {
      color: whiteColor,
      marginBottom: "10px"
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)"
    }
  },
  pricing1: {
    "&$section:after": {
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.8)"
    }
  },
  pricing: {
    padding: "50px 0 0 0"
  },
  textCenter: {
    textAlign: "center"
  },
  sectionSpace: {
    height: "70px",
    display: "block"
  },
  cardCategory: {
    ...description
  },
  cardCategoryWhite: {
    color: whiteColor
  },
  cardDescription: {
    ...description
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  // icon: {
  //   // color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
  //   margin: "10px auto 0",
  //   width: "130px",
  //   height: "130px",
  //   border: "1px solid " + grayColor[14],
  //   borderRadius: "50%",
  //   lineHeight: "174px",
  //   "& .fab,& .fas,& .far,& .fal,& .material-icons": {
  //     fontSize: "55px",
  //     lineHeight: "55px"
  //   },
  //   "& svg": {
  //     width: "55px",
  //     height: "55px"
  //   }
  // },
  iconWhite: {
    color: whiteColor
  },
  iconRose: {
    color: roseColor[0]
  },
  marginTop30: {
    marginTop: "30px"
  },
  marginBottom20: {
    marginBottom: "20px"
  },
  marginBottom30: {
    marginBottom: "30px"
  }
};

export default pricingSection;
