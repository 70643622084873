/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import Category from "@material-ui/icons/Category";
import AccessTime from "@material-ui/icons/AccessTime";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/homeSections/projectsStyle.js";

import cardProject1 from "assets/img/headers/favs/favs6.jpg";
import cardProject2 from "assets/img/headers/favs/favs9.jpg";
import cardProject3 from "assets/img/headers/team.jpg";

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.projects}>
        <div className={classes.container}>
        <GridContainer>
          <GridItem md={8} sm={8} className={classNames(classes.mrAuto, classes.mlAuto)}>
            <h2 className={classNames(classes.title, classes.textCenter)}>
              ABOUT US
            </h2>
          </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card
                raised
                background
                style={{ backgroundImage: `url(${cardProject1})` }}
              >
                <CardBody background>
                    <h3 className={classes.cardTitleWhite}>
                      OUR APPROACH
                    </h3>
                  <p className={classes.cardDescription}>
                    Utah Swim Academy has assisted thousands of children, teens, and even adults learn to swim. Our one on one approach creates measurable results with each lesson....
                  </p>
                  <Button round color="primary" href={"/approach"}>
                    <Category /> LEARN MORE
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card
                raised
                background
                style={{ backgroundImage: `url(${cardProject2})` }}
              >
                <CardBody background>
                  <h3 className={classes.cardTitleWhite}>
                    FIRST DAY EXPECTATIONS
                  </h3>
                  <p className={classes.cardDescription}>
                  You've booked your kids lessons so what's next? The first day can be exciting and sometimes a little nerve-racking. Here are our tips to help you and your little one out...
                  </p>
                  <Button round color="secondary" href={"/first"}>
                  <AccessTime />FIND OUT MORE</Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                raised
                background
                style={{ backgroundImage: `url(${cardProject3})` }}
              >
                <CardBody background>
                  <h3 className={classes.cardTitleWhite}>
                    OUR TEAM
                  </h3>
                  <p className={classes.cardDescription}>
                  Selecting the perfect instructor begins with knowing your child's personality. Read more about our amazing instructors and match them up to your child's personality...
                  </p>
                  <Button round color="warning" href={"/team"}>
                    <People /> VIEW OUR TEAM
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
