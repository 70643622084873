import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import * as UTILS from 'utils';
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/homeSections/teamsStyle.js";

import InfantImg from "assets/img/services/infant.jpg";
import ToddlerImg from "assets/img/services/toddler.jpg";
import StrokeImg from "assets/img/services/stroke.jpg";
import TeamImg from "assets/img/services/team.jpg";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.team}>
        <div className={classes.containerFluid}>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8} className={classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter}>
              <h2 className={classes.title}>OUR SERVICES</h2>
              <h5 className={classes.description}>
              Our program has stood out above and beyond all others year after year. Why? All of our lessons are private, so your child will receive one-on-one instruction in an undisturbed setting. Your child is unique and so are their learning needs and abilities. <strong>It's getting into our busy season and we book up fast</strong>, so make sure to follow us on <a className={classes.link} href="https://www.instagram.com/utah.swim.academy/" target="_blank" rel="noopener noreferrer">Instagram</a> or <a className={classes.link} href="https://utahswimacademy.us17.list-manage.com/subscribe?u=24e95de980f906b5441c857e2&id=976dbd630a" target="_blank" rel="noopener noreferrer">SIGN UP</a> on our email list to know which days we are opening up the schedule!
              </h5>
              {/* <h3 className={classes.description} style={{marginTop: -40, fontWeight: 800, color: 'red'}}>WE ARE CURRENTLY BOOKED FULL FOR {UTILS.getMonth().toUpperCase()} </h3> */}
              {/* <h5 className={classes.description} style={{fontWeight: 800, color: 'red'}}>Due to popular demand, we are not accepting new students at this time. We look forward to having new students join us in a few months!</h5> */}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                    <img src={InfantImg} alt="..." className={classes.img} />
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>INFANT LESSONS</h4>
                  <p className={classes.description}>
                    You can start your infant into survival swimming lessons as soon as 4 months old! We typically like to see little ones when they are starting to sit assisted or unassisted.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                    <img src={ToddlerImg} alt="..." className={classes.img} />
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>TODDLER LESSONS</h4>
                  <p className={classes.description}>
                    Toddlers make incredible little swimmers! We consider our toddler program to start at the age of 1 to 3 years old. During their lessons with us, we will focus primarily on survival skills.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                    <img src={StrokeImg} alt="..." className={classes.img} />
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>STROKE WORK</h4>
                  <p className={classes.description}>
                    No matter your child's age, they can begin learning strokes. ​They begin learning Freestyle and Backstroke. Then Butterfly, Breast Stroke, Flip turns and Racing Dives.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                    <img src={TeamImg} alt="..." className={classes.img} />
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>SWIM TEAM</h4>
                  <p className={classes.description}>
                    Designed to assist children with the transition of private lessons to a non competitive sports team, we teach kids how to swim with others and how to perform on a team.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8} className={classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter}>
              <br />
              <Button href="/services" color="info" size="lg" round>LEARN MORE ABOUT THE SERVICES WE OFFER</Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
