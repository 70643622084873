import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import pricingStyle from "assets/jss/material-kit-pro-react/views/homeSections/socialStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionSocialNetwork() {
  const classes = useStyles();
  return (
      <div className={classes.containerFluid}>
        <GridContainer>
          <GridItem
            md={8}
            sm={10}
            className={classNames(classes.mlAuto, classes.mrAuto)}
          >
            <h2 className={classes.title}>
              CONNECT WITH US
            </h2>
          </GridItem>
          <div className={classes.socialLine}>
            <div className={classes.container}>
              <GridContainer>
                {window.innerWidth > 900 && <GridItem xs={3}></GridItem>}
                <GridItem md={2} xs={12}>
                  <Button color="white" href="https://www.facebook.com/utahswimacademy/" target="_blank" round className={classes.button}>
                    <i className={"fab fa-facebook " + classes.marginRight} />
                    Facebook
                  </Button>
                </GridItem>
                <GridItem md={2} xs={12}>
                  <Button color="white" href="https://www.youtube.com/channel/UCQHF8yxiTBwjFHQqNBGJExg" target="_blank" round className={classes.button}>
                    <i className={"fab fa-youtube " + classes.marginRight} />
                    YouTube
                  </Button>
                </GridItem>
                <GridItem md={2} xs={12}>
                  <Button color="white" href="https://www.instagram.com/utah.swim.academy/" target="_blank" round className={classes.button}>
                    <i className={"fab fa-instagram " + classes.marginRight} />
                    Instagram
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </GridContainer>
      </div>
  );
}
