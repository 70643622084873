import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// import * as UTILS from 'utils';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import contactStyle from "assets/jss/material-kit-pro-react/views/servicesSections/contactStyle.js";

const useStyles = makeStyles(contactStyle);

export default function SectionContact() {
  const classes = useStyles();
  return (
    <div className={classes.aboutUs}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classNames(classes.title, classes.textCenter)}>
            SCHEDULE A LESSON TODAY
          </h2>
          <h4 className={classNames(classes.description, classes.textCenter)}>
            Our talented team is waiting for you to schedule a swim lessons with us.<br /><br />Come visit us for the best value in swim lessons! Our unique method will get your kids safe around water and make swimmers in no time!<br /><br /><strong>It's getting into our busy season and we book up fast</strong>, so make sure to follow us on <a className={classes.link} href="https://www.instagram.com/utah.swim.academy/" target="_blank" rel="noopener noreferrer">Instagram</a> or <a className={classes.link} href="https://utahswimacademy.us17.list-manage.com/subscribe?u=24e95de980f906b5441c857e2&id=976dbd630a" target="_blank" rel="noopener noreferrer">SIGN UP</a> on our email list to know which days we are opening up the schedule!
          </h4>
          {/* <h3 className={classNames(classes.description, classes.textCenter)} style={{marginBottom: 0, fontWeight: 800, color: 'red'}}>WE ARE CURRENTLY BOOKED FULL FOR {UTILS.getMonth().toUpperCase()} </h3> */}
          {/* <h4 className={classNames(classes.description, classes.textCenter)} style={{marginBottom: 50, fontWeight: 800, color: 'red'}}>Due to popular demand, we are not accepting new students at this time. We look forward to having new students join us in a few months!</h4> */}
        </GridItem>
        </GridContainer>
        <GridContainer>
        <GridItem md={2} sm={1} className={classNames(classes.mrAuto, classes.mlAuto)}>
          <Button color="info" round href="https://swim-academy.herokuapp.com/provo-pool/scheduler" target="_blank" fullWidth>
            SCHEDULE A LESSON
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
