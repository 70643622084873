import React from "react";
// database
import * as DATABASE from 'db';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import teamStyle from "assets/jss/material-kit-pro-react/views/servicesSections/teamStyle.js";

const useStyles = makeStyles(teamStyle);

export default function SectionTeam() {
  const classes = useStyles();
  let lessons = DATABASE.LESSONS_LIST
  if(window.innerWidth < 901){ lessons.splice(3,1)};
  return (
    <div className={classes.team}>
      <GridContainer className={classes.services}>
        <GridItem md={12} sm={12}>
        <h1 className={classes.title} style={{ fontSize: window.innerWidth > 900 ? 53 : 35 }}>S E R V I C E S</h1>
        </GridItem>
        {lessons.map(lesson => 
        <GridItem md={lesson.size} sm={12} key={lesson.title}>
          <Card profile plain>
            <CardAvatar profile plain>
              {lesson.link ?
              <a href={lesson.link}>
                <img src={lesson.img} alt={lesson.title} className={classes.img} />
              </a>
              :
              <img src={lesson.img} alt={lesson.title} className={classes.img} />
              }
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>{lesson.title}</h4>
              <p className={classes.cardDescription}>{lesson.description}</p>
            </CardBody>
          </Card>
        </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
