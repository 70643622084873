import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import privacyStyle from "assets/jss/material-kit-pro-react/views/privacySections/privacyStyle.js";

const useStyles = makeStyles(privacyStyle);

export default function SectionPrivacy() {
  const classes = useStyles();
  return (
    <div className={classes.featuresSection}>
        <h2 className={classes.title + " " + classes.textCenter}>COMING SOON</h2>
        <h4 className={classes.title + " " + classes.textCenter}>We are updating our Privacy Policy and will have it up soon</h4>
    </div>
  );
}
