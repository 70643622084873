import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Pool from "@material-ui/icons/Pool";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import People from "@material-ui/icons/People";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/approachSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>WE SPECIALIZE IN</h2>
              <h5 className={classes.description}>
              Utah Swim Academy was founded by JoD Jones in 2005. A family owned and operated business that has assisted thousands of children, teens, and even adults learn to swim. Our one on one approach creates measurable results with each lesson.  
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Pool}
                title="INFANT SURVIVAL SWIMMING"
                description="We assess each student's swimming skill level and develop a personalized program for them"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={VerifiedUser}
                title="SPECIAL NEEDS WATER THERAPY"
                description="We take a careful approach to personalize every lesson for all of our students' needs"
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={People}
                title="FUN FOR PARENTS & CHILDREN"
                description="When absolute safety is achieved, students gain confidence, and parents are ecstatic"
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
