import InfantImg from "assets/img/services/infant.jpg";
import ToddlerImg from "assets/img/services/toddler.jpg";
import StrokeImg from "assets/img/services/stroke.jpg";
import TeamImg from "assets/img/services/team.jpg";
import PoolImg from "assets/img/services/pool.jpg";

export const LESSONS_LIST = [
    {
      title: "INFANT LESSONS",
      description: "You can start your infant into survival swimming lessons as soon as 4 months old! We typically like to see little ones when they are starting to sit assisted or unassisted.",
      img: InfantImg,
      size: 4
    },
    {
      title: "TODDLER LESSONS",
      description: "Toddlers make some incredible little swimmers! We consider our toddler program to start at the age of 1 to 3 years old. During their lessons with us, we will focus primarily on survival skills.",
      img: ToddlerImg,
      size: 4
    },
    {
      title: "STROKE WORK",
      description: "No matter your child's age, they can begin learning strokes. In our program we always test a child's safety skills and then move them into this lesson plan. ​They begin learning Freestyle and Backstroke. Then Butterfly, Breast Stroke, Flip turns and Racing Dives.",
      img: StrokeImg,
      size: 4
    },
    {
      title: "",
      description: "",
      img: "",
      size: 2
    },
    {
      title: "SWIM TEAM",
      description: "This group is designed to assist children with the transition of private swim lessons to a non competitive sports team. This teaches kids how to swim with others and learn how to perform on a rec center team or competitive team.",
      img: TeamImg,
      size: 4
    },
    {
      title: "POOL RENTAL",
      description: "Wanna throw a pool party but have no pool? Rent our pool and be the life of the party. Contact us for more information about renting out our pool for a few hours to a whole day! We're open year round!",
      img: PoolImg,
      size: 4
    }
  ]