/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import SectionPricing from "views/PricingPage/Sections/SectionPricing.js";
import SectionFeatures from "views/PricingPage/Sections/SectionFeatures.js";
import SectionServices from "views/PricingPage/Sections/SectionServices.js";
import SectionEmailSubscription from "views/ContactUsPage/Sections/SubscribeLine.js";
import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function PricingPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Tracker();
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/headers/favs/favs4.jpg")}
        small
        title="Our Prices"
        description="Come visit us for the best value in swim lessons!"
        backgroundPosition={"center bottom"} />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <SectionPricing />
          {/* <SectionFeatures /> */}
        </div>
        <div className={classes.containerFluid}>
          <SectionServices /><br /><br />
        </div>
      </div>
      <SectionEmailSubscription />
      <Footer theme="white" />
    </div>
  );
}
