// images must be 400 x 400
import samanthaRizzo from "assets/img/reviews/samanthaRizzo.jpg";
import rachelCruser from "assets/img/reviews/rachelCruser.jpg";
import janaeGoodrich from "assets/img/reviews/janaeGoodrich.jpg";
import jillTaylor from "assets/img/reviews/jillTaylor.jpg";
import andiePeterson from "assets/img/reviews/andiePeterson.jpg";
import katieWebber from "assets/img/reviews/katieWebber.jpg";

export const REVIEWS_LIST = [
    {
        img: samanthaRizzo,
        name: "Samantha Rizzo",
        title: "January 3rd, 2020",
        rating: 5,
        review: `Because my first two children (ages 7 and 4) have been aquaphobic their entire lives, when our third child was born and we later discovered she loved the water, we knew we wanted to sign her up for swim lessons here so she could be safe near water and also so she wouldn't develop a fear of water from watching her older siblings. We started her when she was 9 months old, and she loved it and did so well with her lessons! Within only a few weeks of lessons she could float unassisted, go down the slide into the pool (and then roll and float so she would come up face up and remain floating), and she could retrieve rings off the bottom of the pool floor with a small push from her instructor. (Getting the rings was her favorite.) All of our friends and family were so amazed that a baby could do this, and seeing their baby sister be safe and having fun in the water gave my older kids the nudge they needed. My 7 year old has had less than 10 lessons and is no longer afraid of the water and can swim by herself, and while my 4 year old still struggles with his fear, he has gotten more confident and can at least float, and his instructor always made it fun for him. The skills they teach here really do work, and it's worth every penny. We'd recommend them to everyone!`
    },
    {
        img: rachelCruser,
        name: "Rachel Cruser",
        title: "December 2nd, 2019",
        rating: 5,
        review: `Utah Swim Academy has been a blessing in our family. The swim lessons provided to my 2 yr old child has empowered her to LOVE the water. It has been so fun as a parent to see her "show off" her swim skills when we go to the local community pools. Other parents often praise her for how well she does in the water. Even a local Firefighter thanked me for allowing her to do survival swim lessons due to his strong dislike of answering 'that type of call' (can anyone blame him?). We wouldn't have been able to experience any of this without the Swim Academy. Being able to match my child's personality to the instructor's, their patience with my child, and the fun environment they create will have been recommending this place to everyone. Worth every penny!`
    },
    {
        img: janaeGoodrich,
        name: "JaNae Goodrich",
        title: "November 29th, 2019",
        rating: 5,
        review: `I was happy to hear that you do your best to place each child with the right instructor in regards to personality. I like that you want each child to succeed. My sons instructor gives him the push that he needs with the right amount of love. I love that lessons are done year round so my kid will be able to keep up on his skills. We've been going for the past few months and have really enjoyed it. They are personable and kind. I would recommend any child go here to learn how to swim.`
    },
    {
        img: jillTaylor,
        name: "Jil Taylor",
        title: "October 17th, 2019",
        rating: 5,
        review: `Best swim lesson experience we’ve ever experienced. The teachers are phenomenal. After several years of lessons elsewhere and not being able to swim we tried Utah Swim Academy and love it. After the first 20 minute lesson my little came away knowing so much and her confidence has continued to grow. Thanks Utah Swim Academy!!!  Best investment EVER for my family!!!`
    },
    {
        img: andiePeterson,
        name: "Andie Shields Peterson",
        title: "September 11th, 2019",
        rating: 5,
        review: `I can't recommend Utah Swim Academy enough! The peace of mind I have knowing that my daughter has the skills necessary to help save herself if she were to ever fall into water is priceless. The instructors are all amazing. They are so patient with the kids, but also help push them. I'm so glad I found this place, they are the best!`
    },
    {
        img: katieWebber,
        name: "Katie Resendiz Webber",
        title: "June 14th, 2019",
        rating: 5,
        review: `I always said if I found something that would make my kids water safe I would spend $1,000,000. Luckily I didn’t have to pay that and this program actually works. These are not just swimming lessons that get your kid familiar with water. These are lessons that teach your child how to survive if they ever fall in and get safely out. These lessons are truly invaluable! Every year I see articles of children who have drowned and it has always been my greatest fear. I am so glad that I have found a program that keeps my kids safe and teaches them they can do hard things!`
    }
  ]