import {
  container,
  title,
  description,
  infoColor
} from "assets/jss/material-kit-pro-react.js";

const sectionSubscribeLineStyle = {
  container: {
    ...container,
    color: 'white!important'
  },
  title: {
    ...title,
    color: 'white'
  },
  description: {
    ...description,
    color: 'white'
  },
  section: {
    padding: "1.875rem 0",
    backgroundColor: infoColor[2],
    color: 'white!important'
  },
  cardClasses: {
    marginTop: "30px"
  },
  icon: {
    color: 'white'
  },
  formControl: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    margin: "0px !important"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    color: infoColor[2],
    "&:hover,&:focus": {
      backgroundColor: infoColor[1],
      color: 'white'
    },
    marginBottom: `${window.innerWidth > 900 ? "0px" : "25px"}!important`,
  }
};

export default sectionSubscribeLineStyle;
