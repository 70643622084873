import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// import * as UTILS from 'utils';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/servicesSections/officeStyle.js";

// office
import office1 from "assets/img/services/office1.jpg";
import office2 from "assets/img/services/office2.jpg";
import office3 from "assets/img/services/office3.jpg";
import office4 from "assets/img/services/office4.jpg";
import office5 from "assets/img/services/office5.jpg";

const useStyles = makeStyles(officeStyle);

export default function SectionOffice() {
  const classes = useStyles();
  let pictures = [
    {
      img: office1,
      alt: "Utah Swim Academy Child Floating",
      md: 4,
      sm: 4
    },
    {
      img: office3,
      alt: "Utah Swim Academy Pool",
      md: 4,
      sm: 4
    },
    {
      img: office2,
      alt: "Utah Swim Academy Child Diving",
      md: 4,
      sm: 4
    },
    {
      img: office5,
      alt: "Utah Swim Academy Umbrellas In Water",
      md: 6,
      sm: 6
    },
    {
      img: office4,
      alt: "Utah Swim Academy Learning to Swim",
      md: 6,
      sm: 6
    },
  ]
  return (
    <div className={classes.office}>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto, classes.personalSection)}
        >
          <h2 className={classes.title}>PERSONALIZED LESSONS</h2>
          <h4 className={classes.description}>
          Our program has stood out above and beyond all others year after year. Why? All of our lessons are private, so your child will receive one-on-one instruction in an undisturbed setting. Your child is unique and so are their learning needs and abilities.<br /><br />To personalize each lesson, our instructors personality profile their students and customize the lesson plan to best benefit their student. You will see progress almost immediately.<br /><br />Our Indoor Swimming Pool Allows Us To Teach Year Round! <strong>It's getting into our busy season and we book up fast</strong>, so make sure to follow us on <a className={classes.link} href="https://www.instagram.com/utah.swim.academy/" target="_blank" rel="noopener noreferrer">Instagram</a> or <a className={classes.link} href="https://utahswimacademy.us17.list-manage.com/subscribe?u=24e95de980f906b5441c857e2&id=976dbd630a" target="_blank" rel="noopener noreferrer">SIGN UP</a> on our email list to know which days we are opening up the schedule!
          </h4>
          {/* <h3 className={classes.description} style={{fontWeight: 800, color: 'red'}}>WE ARE CURRENTLY BOOKED FULL FOR {UTILS.getMonth().toUpperCase()} </h3> */}
          {/* <h5 className={classes.description} style={{fontWeight: 800, color: 'red'}}>Due to popular demand, we are not accepting new students at this time. We look forward to having new students join us in a few months!</h5> */}
        </GridItem>
      </GridContainer>
      <hr />
      <GridContainer>
        {pictures.map(pic => 
          <GridItem md={pic.md} sm={pic.sm} key={pic.alt}>
            <img
              className={classNames(
                classes.imgRaised,
                classes.imgFluid,
                classes.rounded
              )}
              src={pic.img}
              alt={pic.alt}
            />
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
