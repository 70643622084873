import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import contactStyle from "assets/jss/material-kit-pro-react/views/servicesSections/contactStyle.js";

const useStyles = makeStyles(contactStyle);

export default function SectionContact() {
  const classes = useStyles();
  return (
    <div className={classes.aboutUs}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classNames(classes.title, classes.textCenter)}>
            HAVE A QUESTION OR CONCERN?
          </h2>
          <h4 className={classNames(classes.description, classes.textCenter)}>
          If you have a question or concern feel free to Contact Us!<br /><br />View our address, hours, and contact information.<br />If you want to get in touch with your specific instructor, their email is listed in their bios under the About Us tab!<br />We look forward to seeing you at the pool!
          </h4>
        </GridItem>
        </GridContainer>
        <GridContainer>
        <GridItem md={2} sm={1} className={classNames(classes.mrAuto, classes.mlAuto)}>
          <Button color="info" round href="/contact" fullWidth>
            CONTACT US
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
