import {
  title,
  description,
  mrAuto,
  mlAuto,
  infoColor,
  primaryBoxShadow
} from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const contactStyle = {
  title: {
    ...title,
    color: "red",
    fontSize: `${window.innerWidth > 900 ? "14px" : "12px"}!important`
  },
  mrAuto,
  mlAuto,
  ...customSelectStyle,
  description: {
    ...description,
    marginBottom: "30px",
    fontSize: `${window.innerWidth > 900 ? "16px" : "16px"}!important`
  },
  textCenter: {
    textAlign: "center!important"
  },
  selectUnderlineRoot: {
    "& > div": {
      marginTop: "13px"
    }
  },
  aboutUs: {
    padding: "0px 0px 20px 0px"
  },
  mailForm: {
    width: "100%",
    marginBottom: 20,
    "& > input": {
      fontSize: 20,
      width: "100%",
      padding: 10,
      margin: "10px 0",
    },
    "& > button": {
      fontSize: 20,
      width: "100%",
      padding: 20,
      display: 'block',
      margin: "20px auto",
      color: 'white',
      backgroundColor: "#139ADD",
      border: "none",
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: infoColor[1],
        ...primaryBoxShadow,
        cursor: "pointer"
      }
    },
    "& > div > p": {
      fontSize: 16,
      textAlign: "center"
    }
  }
};

export default contactStyle;
