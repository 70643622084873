import {
  title,
  description,
  mrAuto,
  mlAuto,
  infoColor
} from "assets/jss/material-kit-pro-react.js";

const servicesStyle = {
  title: {
    ...title,
    color: infoColor[0],
    fontSize: `${window.innerWidth > 900 ? "50px" : "25px"}!important`
  },
  mrAuto,
  mlAuto,
  services: {
    padding: "100px 50px"
  },
  textCenter: {
    textAlign: "center"
  },
  description,
  paragraphs: {
    height: window.innerWidth < 1400 ? window.innerWidth < 1280 && window.innerWidth > 400 ? 100 : 200 : 150
  },
  link: {
    textDecoration: "none",
    color: infoColor[0],
    "&:visited": {
      color: infoColor[0]
    },
    "&:hover, &:focus": {
      color: infoColor[0],
      opacity: '0.7'
    }
  }
};

export default servicesStyle;
