import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/approachSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.features5}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>FOOD GUIDELINES</h2>
            <h4 className={classes.title}>Foods your child can eat before their swim lesson</h4>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={6} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={CheckCircleOutline}
                  title="OK FOODS"
                  description={
                    <div>
                      <p>Apple sauce</p>
                      <p>Juices</p>
                      <p>Bananas</p>
                      <p>Finely processed oatmeal</p>
                    </div>
                  }
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={6} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={HighlightOff}
                  title="NOT OKAY FOODS"
                  description={
                    <div>
                      <p><strong>Harder to Digest Fruits</strong><br />ie: strawberries, grapes, oranges</p>
                      <p><strong>Dairy Product</strong><br />Milk products do not come up well. Please try to avoid all milk or milk products</p>
                      <p><strong>Bread</strong><br />Breads and other carbs are hard to clean up as they're heavy and usually not digested properly</p>
                    </div>
                  }
                  iconColor="danger"
                />
              </GridItem>
            </GridContainer>
          </div>
        </GridContainer>
      </div>
    </div>
  );
}
