import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
import People from "@material-ui/icons/People";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Pool from "@material-ui/icons/Pool";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/schedulingSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>HOW IT WORKS</h2>
              <h5 className={classes.description}>
             Welcome to the Utah Swim Academy Team! To get started, you'll begin by getting to know our instructors a little bit more, using our Self-Scheduling System to purchase and schedule lessons, and come prepared to your child's private 20 minute lesson. So let's get started!
              </h5>
            </GridItem>
          </GridContainer>
          <br /><br />
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={People}
                title="OUR INSTRUCTORS"
                description="We recommend to use the same 2-3 instructors for consistency. We teach usually from 8am-7pm. It varies a little just depending on what instructors can do for that month."
                iconColor="primary"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={ExitToApp}
                title="SELF-SCHEDULING SYSTEM"
                description="Use our Do-It-Yourself Self-Scheduling system to schedule your child's lessons. Scheduling is first come, first serve. Lessons go fast so you'll want to be prepared."
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Pool}
                title="20 MINUTE LESSONS"
                description="After scheduling a lesson, expect to arrive to the pool 5 to 10 minutes early to have your child ready for thier lesson. Each private lesson will run for 20 minutes."
                iconColor="info"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classes.features5}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>SELF-SCHEDULING SYSTEM</h2>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={ExitToApp}
                  title="CREATE AN ACCOUNT"
                  description={
                    <span>
                      <p className={classes.paragraphs}>
                        You'll want to create an account on our Self-Scheduling System before the schedules are posted. Lessons go fast so you will want to be prepared.{" "}
                      </p>
                      <Button href="https://swim-academy.herokuapp.com/users/sign_in" color="info" target="_blank" fullWidth>
                        Create An Account
                      </Button>
                    </span>
                  }
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={AttachMoney}
                  title="PURCHASE LESSONS"
                  description={
                    <span>
                      <p className={classes.paragraphs}>
                        Once you're logged in, click on "Buy Lessons" in the upper left corner menu and you'll be able to select the amount of lessons you wish to buy. Each private lesson goes for 20 minutes.{" "}
                      </p>
                      <Button href="https://swim-academy.herokuapp.com/our_lessons" color="info" target="_blank" fullWidth>
                        Purchase Lessons
                      </Button>
                    </span>
                  }
                  iconColor="danger"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Dashboard}
                  title="SCHEDULE LESSONS"
                  description={
                    <span>
                      <p className={classes.paragraphs}>
                        Use our self-scheduling system to schedule your child with one of our Swim Instructors. Learn more about our team and find the perfect instructor for your child.{" "}
                      </p>
                      <Button href="https://swim-academy.herokuapp.com/provo-pool/scheduler" color="info" target="_blank" fullWidth>
                        Schedule Lessons
                      </Button>
                    </span>
                  }
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={People}
                  title="SELECT AN INSTRUCTOR"
                  description={
                    <span>
                      <p className={classes.paragraphs}>
                        You'll get to choose your instructor as you schedule the lessons. We recommend sticking with one instructor or switching between 2. Research out team to find the perfect instructor for your child.{" "}
                      </p>
                      <Button href="/team" color="info" fullWidth>
                        View Our Team
                      </Button>
                    </span>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={AccessTime}
                  title="HOW OFTEN"
                  description={
                    <span>
                      <p className={classes.paragraphs}>
                        We recommend coming 4-5 times a week for the first 2 weeks of lessons and then coming about twice a week after that. Of course you can come as often as you'd like!{" "}
                      </p>
                      <Button href="/first" color="info" fullWidth>
                        Getting Started
                      </Button>
                    </span>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Pool}
                  title="STAY UP-TO-DATE"
                  description={
                    <span>
                      <p className={classes.paragraphs}>
                        Sign up to be on our email list to stay up-to-date with our instructors, sales, and latest news with the Utah Swim Academy.{" "}
                      </p>
                      <Button href="https://utahswimacademy.us17.list-manage.com/subscribe?u=24e95de980f906b5441c857e2&id=976dbd630a" target="_blank" color="info" fullWidth>
                        Join Our Email List
                      </Button>
                    </span>
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
        </GridContainer>
      </div>
    </div>
  );
}
