/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionTeam from "views/ServicesPage/Sections/SectionTeam.js";
import SectionOffice from "views/ServicesPage/Sections/SectionOffice.js";
import SectionContact from "views/ServicesPage/Sections/SectionContact.js";
import SectionServices from "views/PricingPage/Sections/SectionServices.js";
import SectionEmailSubscription from "views/ContactUsPage/Sections/SubscribeLine.js";
import servicesStyle from "assets/jss/material-kit-pro-react/views/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function ServicesPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Tracker();
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/headers/services.jpg")}
        small
        title="Our Services"
        description="Our Purpose And Passion: Saving Lives, One Person At A Time!"
        backgroundPosition={"center center"} />
      <div className={classNames(classes.main)}>
        <div className={classes.containerFluid}>
          <SectionServices /><br />
          <SectionTeam />
        </div>
        <div className={classes.container}>
          <SectionOffice />
          <hr />
          <SectionContact />
        </div>
      </div>
      <SectionEmailSubscription />
      <Footer theme="white" />
    </div>
  );
}
