import {
  title,
  mlAuto,
  mrAuto,
  infoColor
} from "assets/jss/material-kit-pro-react.js";

const featuresStyle = {
  featuresSection: {
    padding: "80px 0px"
  },
  textCenter: {
    textAlign: "center"
  },
  title: {
    ...title,
    color: infoColor[0],
    fontSize: `${window.innerWidth > 900 ? "50px" : "35px"}!important`
  },
  description: {
    ...title
  },
  mlAuto,
  mrAuto
};

export default featuresStyle;
