import { main, container, infoColor } from "assets/jss/material-kit-pro-react.js";

const sectionsPageStyle = {
  main: {
    ...main
  },
  container: {
    ...container
  },
  link: {
    color: infoColor[0],
    fontWeight: 600,
    "&:hover,&:focus": {
      cursor: "pointer",
      color: infoColor[4]
    }
  }
};

export default sectionsPageStyle;
