import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import termsStyle from "assets/jss/material-kit-pro-react/views/termsSections/termsStyle.js";

const useStyles = makeStyles(termsStyle);

export default function SectionTerms() {
  const classes = useStyles();
  return (
    <div className={classes.featuresSection}>
        <h2 className={classes.title + " " + classes.textCenter}>COMING SOON</h2>
        <h4 className={classes.title + " " + classes.textCenter}>We are updating our Terms of Service and will have them up soon</h4>
    </div>
  );
}
