import React, {Fragment} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-pro-react/components/parallaxStyle.js";
import SmallLogo from "assets/img/logos/logo.svg";

const useStyles = makeStyles(styles);

export default function Parallax(props) {
  let windowScrollTop;
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3;
  } else {
    windowScrollTop = 0;
  }
  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  );
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };
  const { filter, className, style, image, small, backgroundPosition, title, description } = props;
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes[filter + "Color"]]: filter !== undefined,
    [classes.small]: small,
    [className]: className !== undefined
  });
  return (
    <div
      className={parallaxClasses}
      style={{
        ...style,
        backgroundImage: "url(" + image + ")",
        transform: transform,
        backgroundPosition: backgroundPosition ? backgroundPosition : "center top",
        marginTop: 62
      }}
    >
      <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <img src={SmallLogo} alt={title} width="120px" className={classes.wrapperImg} />
                {window.innerWidth > 900 ? <Fragment></Fragment> : <br />}
                <h1 className={classes.wrapperTitle}>
                  {title}
                </h1>
                <h3 className={classes.wrapperDescription}>
                  {description}
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
    </div>
  );
}

Parallax.defaultProps = {
  title: "Utah Swim Academy",
  description: "EMPOWERING PARENTS & CHILDREN WITH WATER"
}

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "info",
    "success",
    "warning",
    "danger"
  ]),
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool
};
