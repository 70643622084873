import {
  mrAuto,
  mlAuto,
  title,
  description,
  cardTitle,
  grayColor,
  infoColor,
  container,
  // blackColor,
  // hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const teamStyle = {
  ...container,
  mrAuto,
  mlAuto,
  title: {
    ...title,
    textAlign: "center",
    color: infoColor[1],
    margin: "0px!important",
    padding: "0 0 20px 0!important",
    fontWeight: 900,
    lineHeight: "1.3",
    fontSize: 30
  },
  subtitle: {
    ...title,
    textAlign: "center",
    color: infoColor[0],
    margin: "0px!important",
    padding: '5px 0px 10px 0px!important',
    fontSize: 16
  },
  body1: {
    textAlign: "center",
    marginTop: 20
  },
  body2: {
    textAlign: "center",
    fontWeight: 900,
    marginTop: 20
  },
  body3: {
    textAlign: "center",
    fontWeight: 900,
    marginBottom: 20
  },
  body4: {
    textDecoration: 'underline',
    color: "orange"
  },
  body5: {
    marginBottom: 20
  },
  bodyList: {
    marginTop: 30,
    marginBottom: 20,
    listStyleType: "none",
    paddingLeft: 30
  },
  list: {
    marginBottom: 20,
    fontSize: 20,
    "& i": {
      color: infoColor[0],
      fontSize: 20,
      marginRight: 10
    }
  },
  description: {
    ...description,
    marginBottom: "80px",
    fontWeight: 400
  },
  cardTitle: {
    ...cardTitle,
    color: 'white!important'
  },
  cardDescription: {
    color: 'white',
    fontWeight: 400
  },
  wrapper: {
    padding: "80px 0px 0px 0px",
  },
  textCenter: {
    textAlign: "center!important"
  },
  img: {
    width: "100%",
    height: "auto"
  },
  textMuted: {
    color: grayColor[7] + " !important"
  },
  justifyContent: {
    justifyContent: "center!important"
  },
  iframeContainer: {
    margin: 0,
    width: "100%",
    // boxShadow:
    //   "0 16px 38px -12px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.56), 0 4px 25px 0px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.12), 0 8px 10px -5px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.2)",
    overflow: "hidden",
    // borderRadius: 10 
  },
  services: {
    backgroundColor: infoColor[0],
    padding: window.innerWidth > 900 ? "100px 50px" : "100px 20px",
  }
};

export default teamStyle;
