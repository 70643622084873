/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
// @material-ui/icons
import Button from "components/CustomButtons/Button.js";
// assets
import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";
import WordLogoLong from "assets/img/logos/logo-word-long.svg";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  let { children, theme, big, className } = props;
  const classes = useStyles();
  theme = theme ? theme : "transparent"
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: true,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div>
            <div className={classes.left}>
              <Link to="/">
                <img src={WordLogoLong} style={{width: 250, marginLeft: window.innerWidth > 900 ? 0 : 55}} alt="Utah Swim Academy" />
              </Link>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/terms" className={classes.block}>
                    Terms Of Service
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/privacy" className={classes.block}>
                    Privacy Policy
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <p className={classes.block}>Utah Swim Academy © {(new Date().getFullYear())} All Rights</p>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="https://www.facebook.com/utahswimacademy/"
                    target="_blank"
                    color="facebook"
                    justIcon
                    simple
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://www.youtube.com/channel/UCQHF8yxiTBwjFHQqNBGJExg"
                    target="_blank"
                    color="youtube"
                    justIcon
                    simple
                  >
                    <i className="fab fa-youtube" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://www.instagram.com/utah.swim.academy/"
                    target="_blank"
                    color="instagram"
                    justIcon
                    simple
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool
};
