import React from "react";
// database
import * as DATABASE from 'db';
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Star from "@material-ui/icons/Star";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";
import Warning from "components/Typography/Warning.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/approachSections/testimonialsStyle.js";

const useStyles = makeStyles(testimonialsStyle);

export default function SectionTestimonials() {
  const classes = useStyles();
  const reviews = DATABASE.REVIEWS_LIST;
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000
  };
  return (
      <div
        className={
          classes.testimonials +
          " " +
          classes.sectionDark +
          " " +
          classes.testimonial2
        }
      >
          <GridContainer style={{ padding: 5 }}>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.title}>DON’T JUST TAKE OUR WORD FOR IT,<br />HERE’S WHAT SOME OF OUR CLIENTS HAVE TO SAY ABOUT OUR TRAINING</h1>
              <Carousel {...settings}>
              {reviews.map((item,i) =>
                <div key={item.name+i}>
                  <Card testimonial plain className={classes.card2}>
                    <CardAvatar testimonial plain>
                        <img src={item.img} alt="..." />
                    </CardAvatar>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        {item.review}
                      </h5>
                      <h4 className={classes.cardTitle}>{item.name}</h4>
                      <Muted>
                        <h6>{item.title}</h6>
                      </Muted>
                    </CardBody>
                    <div>
                        {Array.from(Array(item.rating), (e, i) => {
                            return <Warning key={`${item.name}Star${i}`}>
                            <Star className={classes.starIcons} />
                          </Warning>
                        })}
                    </div>
                  </Card>
                </div>
                )}
              </Carousel>
              <h3 className={classes.subtitle}>“OUR PURPOSE AND PASSION: SAVING LIVES, ONE PERSON AT A TIME”</h3>
            </GridItem>
          </GridContainer>
        </div>
  );
}
