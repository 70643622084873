/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";
import AccessTime from "@material-ui/icons/AccessTime";
import Fastfood from "@material-ui/icons/Fastfood";
import Pool from "@material-ui/icons/Pool";
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/approachSections/projectsStyle.js";

import card1 from "assets/img/approach/firstDay1.jpg";
import card2 from "assets/img/approach/firstDay2.jpg";

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.projects + " " + classes.projects4}>
        <GridContainer>
          <GridItem xs={12} className={classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter}>
            <h2 className={classes.title}>YOUR FIRST DAY</h2>
            <h4>The first day can be exciting and sometimes a little nerve-racking. Here are our tips to help you and your little one out!</h4>
            <br />
          </GridItem>
        </GridContainer>
        <div className={classes.container}>
          <GridContainer>
            {window.innerWidth > 900 &&
            <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>
              <Card product plain>
                <CardHeader image plain color="info">
                  <img src={card1} alt={`Utah Swim Academy - First Day`} />
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${card1})`, opacity: 1 }}
                  />
                </CardHeader>
              </Card>
            </GridItem>
            }
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              {window.innerWidth > 900 && <br />}
              {window.innerWidth > 900 && <br />}
              <InfoArea
                className={classes.info4}
                title="KNOW YOUR SCHEDULE"
                description="It’s always a good idea to double check the time slots you reserved. We don’t want you to miss your lesson or show up on the wrong day!"
                icon={Assignment}
                iconColor="info"
              />
              {window.innerWidth > 900 && <br />}
              <InfoArea
                className={classes.info4}
                title="COME EARLY"
                description="Try to arrive 5-10 minutes early. If you've never been to our pool before, come extra early so you have plenty of time to find it. GPS is not always reliable, so look for the white ‘Utah Swim Academy’ sign along our front fence! Our pool is a back yard residential pool and parking is down our gravel path."
                icon={AccessTime}
                iconColor="success"
              />
              {window.innerWidth > 900 && <br />}
              <InfoArea
                className={classes.info4}
                title="HAVE CHILD READY"
                description="If your child is not potty trained, you must have a swim diaper. We recommend the reusable ones. The brand we suggest is Boop Baby. PLEASE double diaper!"
                icon={Person}
                iconColor="warning"
              />
            </GridItem>
          </GridContainer>
          {window.innerWidth > 900 && <hr />}
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              {/* {window.innerWidth > 900 && <br />} */}
              <InfoArea
                className={classes.info4}
                title="TALK TO YOUR INSTRUCTOR"
                description="When it's your turn, the instructor will call out your child’s name. Head over and chat with your instructor, especially if this is your first lesson. It's important to express any concerns you may have and let them know about your child’s background and personality (how they learn). Also let them know your expectations; it’ll help the instructor know how to proceed with the lesson. Do you want to go at a fast speed or take it a little slower? Communication is so important and we want to make sure you are always kept in the loop and your child is on the right track!"
                icon={Group}
                iconColor="primary"
              />
              {window.innerWidth > 900 && <br />}
              <InfoArea
                className={classes.info4}
                title="WHILE CHILD IS SWIMMING"
                description="Each private lesson will run for 20 minutes. Crying is normal. It is probably best to hide if your child is screaming for you. Don’t worry, this goes away after they bond with us and get a feel for the lesson plan. Please DO NOT get in the pool during lessons, the pool is only for those who are taking lessons."
                icon={Pool}
                iconColor="info"
              />
              {window.innerWidth > 900 && <br />}
              <InfoArea
                className={classes.info4}
                title="EATING GUIDELINES"
                description="Please don’t feed your child too much before the lesson. Throwing up is not uncommon when children first start to swim, and an empty stomach will help. No, we don’t want you to starve your child for their lesson. Just try and feed them 3-4 hours prior to their lesson."
                icon={Fastfood}
                iconColor="danger"
              />
            </GridItem>
            {window.innerWidth > 900 &&
            <GridItem xs={12} sm={6} md={6} className={classes.mlAuto}>
              <Card product plain>
                <CardHeader image plain color="info">
                  <img src={card2} alt={`Utah Swim Academy - First Day`} height={710} />
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${card2})`, opacity: 1 }}
                  />
                </CardHeader>
              </Card>
            </GridItem>
            }
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
