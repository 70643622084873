import {
  title,
  description,
  mrAuto,
  mlAuto,
  infoColor
} from "assets/jss/material-kit-pro-react.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const imgRaised = imagesStyles.imgRaised;
const rounded = imagesStyles.imgRounded;
const imgFluid = imagesStyles.imgFluid;

const servicesStyle = {
  title: {
    ...title,
    color: infoColor[4],
    fontSize: `${window.innerWidth > 900 ? "50px" : "35px"}!important`
  },
  description,
  mrAuto,
  mlAuto,
  textCenter: {
    textAlign: "center!important"
  },
  office: {
    padding: 30,
    "& img": {
      margin: "20px 0px"
    }
  },
  personalSection: {
    // backgroundColor: infoColor[0],
    padding: '0 0 50px 0'
  },
  link: {
    textDecoration: "none",
    color: infoColor[0],
    "&:visited": {
      color: infoColor[0]
    },
    "&:hover, &:focus": {
      color: infoColor[0],
      opacity: '0.7'
    }
  },
  imgRaised,
  rounded,
  imgFluid
};

export default servicesStyle;
