import {
  title,
  description,
  mrAuto,
  mlAuto,
  infoColor,
  container
} from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const contactStyle = {
  title: {
    ...title,
    color: infoColor[0],
    fontSize: `${window.innerWidth > 900 ? "50px" : "35px"}!important`
  },
  mrAuto,
  mlAuto,
  ...customSelectStyle,
  description: {
    ...description,
    marginBottom: "70px"
  },
  textCenter: {
    textAlign: "center!important"
  },
  selectUnderlineRoot: {
    "& > div": {
      marginTop: "13px"
    }
  },
  aboutUs: {
    padding: "80px 0px 0px 0px",
    ...container
  },
  link: {
    textDecoration: "none",
    color: infoColor[0],
    "&:visited": {
      color: infoColor[0]
    },
    "&:hover, &:focus": {
      color: infoColor[0],
      opacity: '0.7'
    }
  }
};

export default contactStyle;
